import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { useContrast } from '../../../context/ContrastContext';
import Text from '../Text/Text';

import './CookieBanner.scss';

const CookieBanner: React.FC = () => {
  const { highContrast } = useContrast();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookies-accepted');
    if (!cookiesAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookies-accepted', 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className={classNames('cookie-banner', { highContrast })}>
      <Text
        className={classNames('cookie-text', { highContrast })}
        fontSize={{
          min: '0.75rem',
          preferred: '0.3rem + 0.8vw',
          max: '1.1rem',
        }}
      >
        Nasza strona internetowa korzysta z plików cookies niezbędnych do jej
        prawidłowego działania. Nie zbieramy danych analitycznych, reklamowych
        ani personalizowanych. Pliki cookies są wykorzystywane wyłącznie w
        celach technicznych, aby zapewnić Ci wygodne korzystanie z witryny.
        Korzystając ze strony, akceptujesz ich użycie.
      </Text>
      <button
        className={classNames('cookie-button', { highContrast })}
        onClick={handleAccept}
      >
        <Text>Rozumiem</Text>
      </button>
    </div>
  );
};

export default CookieBanner;
