import React from 'react';
import classNames from 'classnames';

import Text from '../core/Text/Text';
import './Event.scss';
import { useContrast } from '../../context/ContrastContext';

interface EventProps {
  date: string;
  title: string;
  description: string;
  bottom?: React.ReactNode;
}

const Event: React.FC<EventProps> = ({ date, title, description, bottom }) => {
  const { highContrast } = useContrast();

  return (
    <div className={classNames('event', { highContrast })}>
      <Text
        className={classNames('event-text', { highContrast })}
        as='h3'
        fontSize={{
          min: '1rem',
          preferred: '0.8rem + 1vw',
          max: '1.25rem',
        }}
        isBold
        marginBottom='10px'
      >
        {title}
      </Text>
      <Text className='event-date' marginBottom='10px'>
        {date}
      </Text>
      <Text className={classNames('event-text', { highContrast })}>
        {description}
      </Text>
      {bottom && <div>{bottom}</div>}
    </div>
  );
};

export default Event;
