import React from 'react';
import './Logo.scss';
import { Link } from 'react-router-dom';
import logo from '../../../../assets/images/logoOld.png';
import Text from '../../../core/Text/Text';

const Logo: React.FC = () => (
  <Link to='/' className='navbar-logo'>
    <img
      src={logo}
      alt='Logo Spółdzielnia'
      className='logo-image'
      width={36}
      height={36}
    />
    <Text
      className='logo-text'
      isBold
      fontSize={{
        min: '0.75rem',
        preferred: '0.2rem + 0.8vw',
        max: '1rem',
      }}
    >
      SM „NOWA”
    </Text>
  </Link>
);

export default Logo;
