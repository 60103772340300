import PageLoader from '../core/PageLoader/PageLoader';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Paths } from '../../constants/paths';
import { useContrast } from '../../context/ContrastContext';
import BackButton from '../core/BackButton/BackButton';
import CookieBanner from '../core/CookieBanner/CookieBanner';
import LightGreyBall from '../core/LightGreyBall/LightGrayBall';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import Accessibility from './Accessibility/Accessibility';
import ScrollToTop from './ScrollToTop/ScrollToTop';

import './Layout.scss';

type LayoutProps = {
  children: React.ReactNode;
  hasBackButton?: boolean;
};

const Layout: React.FC<LayoutProps> = ({ children, hasBackButton = false }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { highContrast } = useContrast();
  const location = useLocation();
  const [isLoaderVisible, setIsLoaderVisible] = useState(true); // Set loader visible by default

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const oneBall = [{ size: 1200, fromTop: '9%', fromLeft: '-20%' }];
  const twoBalls = [
    { size: 1200, fromTop: '5%', fromLeft: '-20%' },
    { size: 1200, fromTop: '65%', fromLeft: '40%' },
  ];

  const ballConfigurations: Record<
    string,
    { size: number; fromTop: string; fromLeft: string }[]
  > = {
    [Paths.CONTACT]: oneBall,
    [Paths.DO_YOU_KNOW]: oneBall,
    [Paths.EMERGENCY]: oneBall,
    [Paths.POST]: oneBall,
    [Paths.RESIDENT]: twoBalls,
  };

  const threeBalls = [
    { size: 1200, fromTop: '5%', fromLeft: '-20%' },
    { size: 1200, fromTop: '35%', fromLeft: '40%' },
    { size: 1200, fromTop: '68%', fromLeft: '-20%' },
  ];

  const ballsToRender =
    Object.entries(ballConfigurations).find(([path]) =>
      location.pathname.includes(path)
    )?.[1] || threeBalls;

  useEffect(() => {
    const loaderSeen = localStorage.getItem('new-page-loader-seen');
    if (!loaderSeen) {
      setIsLoaderVisible(true);

      setTimeout(() => {
        localStorage.setItem('new-page-loader-seen', 'true');
        setIsLoaderVisible(false);
      }, 12000);
    } else {
      setIsLoaderVisible(false);
    }
  }, []);

  if (isLoaderVisible) {
    return <PageLoader />;
  }

  return (
    <div className='layout-root'>
      <div className='layout-wrapper'>
        {!highContrast &&
          ballsToRender.map((ball, index) => (
            <LightGreyBall
              key={index}
              size={ball.size}
              fromTop={ball.fromTop}
              fromLeft={ball.fromLeft}
            />
          ))}
        <div>
          <Navbar
            isMobileMenuOpen={isMobileMenuOpen}
            toggleMobileMenu={toggleMobileMenu}
          />
          <main className={classNames('site-content', { highContrast })}>
            {hasBackButton && <BackButton />}
            {children}
          </main>
          <Footer />
          {!isMobileMenuOpen && (
            <>
              <ScrollToTop />
              <Accessibility />
              <CookieBanner />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Layout;
