import {
  faClose,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';

import Icon from '../Icon/Icon';
import type { Post } from '../../SinglePost/SinglePost';
import './Lightbox.scss';

import useMediaQuery from '../../../hooks/useMediaQuery';

type LightboxProps = {
  isOpen: boolean;
  photoIndex: number;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPhotoIndex: React.Dispatch<React.SetStateAction<number>>;
  post: Post;
};

const Lightbox: React.FC<LightboxProps> = ({
  isOpen,
  photoIndex,
  setIsOpen,
  setPhotoIndex,
  post,
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const nextPhoto = useCallback(() => {
    setPhotoIndex(
      (prevIndex) => (prevIndex + 1) % (post?.gallery_photos?.value.length || 1)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post?.gallery_photos?.value]);

  const prevPhoto = useCallback(() => {
    setPhotoIndex(
      (prevIndex) =>
        (prevIndex - 1 + (post?.gallery_photos?.value.length || 1)) %
        (post?.gallery_photos?.value.length || 1)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post?.gallery_photos?.value]);

  const closeLightbox = useCallback(() => {
    setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        nextPhoto();
      } else if (event.key === 'ArrowLeft') {
        prevPhoto();
      } else if (event.key === 'Escape') {
        closeLightbox();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeLightbox, isOpen, nextPhoto, prevPhoto]);

  if (!post) {
    return null;
  }

  return (
    <div className='lightbox'>
      <button
        aria-label='Zamknij'
        className={classNames('close', 'lightbox-button')}
        onClick={closeLightbox}
      >
        <Icon icon={faClose} size='2x' />
      </button>
      <button
        aria-label='Poprzednie zdjęcie'
        className={classNames('prev', 'lightbox-button')}
        onClick={prevPhoto}
      >
        <Icon icon={faChevronLeft} size='2x' />
      </button>
      <div className='lightbox-content'>
        <img
          className='lightbox-content-image'
          src={post.gallery_photos?.value[photoIndex].guid}
          alt=''
        />
      </div>
      <button
        aria-label='Następne zdjęcie'
        className={classNames('next', 'lightbox-button')}
        onClick={nextPhoto}
      >
        <Icon icon={faChevronRight} size='2x' />
      </button>
    </div>
  );
};

export default Lightbox;
