import React from 'react';
import './PageLoader.scss';
import Text from '../Text/Text';

const PageLoader: React.FC = () => {
  return (
    <div className='page-loader-container'>
      <div className='page-loader'></div>
      <div className='loader-texts'>
        <Text
          className='loader-text loader-text-0'
          fontSize={{
            min: '1.2rem',
            preferred: '1rem + 1vw',
            max: '1.5rem',
          }}
        >
          Witaj w SPÓŁDZIELNI MIESZKANIOWEJ „NOWA”
        </Text>
        <Text
          className='loader-text loader-text-1'
          fontSize={{
            min: '1.2rem',
            preferred: '1rem + 1vw',
            max: '1.5rem',
          }}
        >
          Cieszymy się, że jesteś z nami. Zmieniliśmy się dla ciebie!
        </Text>
      </div>
    </div>
  );
};

export default PageLoader;
