import React from 'react';
import classNames from 'classnames';

import LinkButton from '../core/LinkButton/LinkButton';
import Text from '../core/Text/Text';

import './Teaser.scss';
import { useContrast } from '../../context/ContrastContext';

type TeaserProps = {
  title: string;
  text: string;
  link: string;
  image: string;
};

const Teaser: React.FC<TeaserProps> = ({ title, text, link, image }) => {
  const { highContrast } = useContrast();

  return (
    <div className='teaser-box' style={{ backgroundImage: `url(${image})` }}>
      <div className={classNames('teaser-content', { highContrast })}>
        <Text
          isBold
          fontSize={{
            min: '1.2rem',
            preferred: '1rem + 1vw',
            max: '1.5rem',
          }}
          marginBottom='10px'
        >
          {title}
        </Text>
        <Text className='teaser-text'>{text}</Text>
        <LinkButton
          variant='secondary'
          className='teaser-button'
          to={link}
          message='Czytaj dalej'
        />
      </div>
    </div>
  );
};

export default Teaser;
