import { faAdjust, faFont } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';

import { useContrast } from '../../../context/ContrastContext';

import './Accessibility.scss';

const Accessibility: React.FC = () => {
  const { highContrast, setHighContrast } = useContrast();
  const [showAccessibilityPanel, setShowAccessibilityPanel] = useState(false);
  const [fontSizeLevel, setFontSizeLevel] = useState(0);
  const maxFontSizeLevel = 4;

  const increaseFontSize = () => {
    if (fontSizeLevel < maxFontSizeLevel) {
      const currentFontSize = parseFloat(
        window.getComputedStyle(document.documentElement).fontSize
      );
      const newFontSize = currentFontSize * 1.1; // increase by 10%
      document.documentElement.style.fontSize = `${newFontSize / 16}rem`; // convert to rem
      setFontSizeLevel(fontSizeLevel + 1);
    }
  };

  const decreaseFontSize = () => {
    if (fontSizeLevel > -maxFontSizeLevel) {
      const currentFontSize = parseFloat(
        window.getComputedStyle(document.documentElement).fontSize
      );
      const newFontSize = currentFontSize * 0.9; // decrease by 10%
      document.documentElement.style.fontSize = `${newFontSize / 16}rem`; // convert to rem
      setFontSizeLevel(fontSizeLevel - 1);
    }
  };

  const toggleContrast = () => {
    setHighContrast(!highContrast);
    if (!highContrast) {
      document.body.classList.add('high-contrast');
    } else {
      document.body.classList.remove('high-contrast');
    }
  };

  return (
    <div
      className='accessibility'
      onMouseEnter={() => setShowAccessibilityPanel(true)}
      onMouseLeave={() => setShowAccessibilityPanel(false)}
    >
      <button
        aria-label='Dostępność'
        className={classNames('accessibilityButton', { highContrast })}
      >
        <FontAwesomeIcon icon={faFont} size='lg' />
      </button>
      {showAccessibilityPanel && (
        <div className='accessibilityPanel'>
          <button
            className={classNames('button', { highContrast })}
            onClick={increaseFontSize}
            disabled={fontSizeLevel >= maxFontSizeLevel}
          >
            A+
          </button>
          <button
            className={classNames('button', { highContrast })}
            onClick={decreaseFontSize}
            disabled={fontSizeLevel <= -maxFontSizeLevel}
          >
            A-
          </button>
          <button
            aria-label='Włącz/wyłącz wysoki kontrast'
            className={classNames('button', { highContrast })}
            onClick={toggleContrast}
          >
            <FontAwesomeIcon icon={faAdjust} />
          </button>
        </div>
      )}
    </div>
  );
};

export default Accessibility;
