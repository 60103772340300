import React from 'react';
import classNames from 'classnames';
import Text from '../core/Text/Text';
import { useContrast } from '../../context/ContrastContext';
import './WarmInfo.scss';

const WarmInfo: React.FC = () => {
  const { highContrast } = useContrast();

  return (
    <div className='warm-info-container'>
      <div className='warm-info-section'>
        <Text>
          Każdy chciałby, aby jego mieszkanie było ciepłe i przytulne. Niestety
          opłaty za ogrzewanie to nieodłączna część wydatków, zatem warto
          wiedzieć, jak zużywać mniej ciepła i płacić niższe rachunki. Poniżej
          przedstawiamy krótki informator dotyczący prawidłowego i efektywnego
          wykorzystywania energii cieplnej. Zmiana przyzwyczajeń pozwoli
          ograniczyć koszty zużywanej energii nawet o 15%.
        </Text>
      </div>
      <div className='warm-info-section'>
        <Text>
          Cyklicznie wietrz mieszkanie krótko i intensywnie. Krótkotrwały
          przeciąg sprawi, że powietrze będzie świeże, mieszkanie nie ulegnie
          przechłodzeniu, a temperatura zostanie utrzymana.
        </Text>
      </div>
      <div className='warm-info-section'>
        <Text>
          Zawory termostatyczne powinny być ustawione na taką temperaturę, jaką
          chcesz uzyskać w pomieszczeniach. Termozawory służą do regulacji
          temperatury. Proponujemy zaznaczyć na termostacie podziałkę, przy
          której w pomieszczeniu jest pożądana temperatura (w oparciu o
          wskazania termometru w pokoju), aby prosto i łatwo ustawić zawór. Jak
          działa zawór termostatyczny? Zamyka się, gdy osiągnie pożądaną
          temperaturę, otwiera, gdy potrzebuje dogrzać pomieszczenie. Zawory
          termostatyczne – podobnie jak grzejniki – powinny być odsłonięte.
          Zabudowa, zasłonięty termozawór lub kaloryfer zatrzymują ciepło w
          swoim obrębie – nie ogrzewając mieszkania, tylko siebie. Stale
          zasłonięty grzejnik zużywa nawet o 40% więcej ciepła.
        </Text>
      </div>
      <div className='warm-info-section'>
        <Text>
          Łatwo zapamiętać regułę 1/6: wzrost temperatury o 1 stopień powoduje
          wzrost zużycia ciepła o 6%. Proponujemy wyposażyć grzejniki w ekrany
          ścienne z folii aluminiowej odbijające energię cieplną (dają nawet 5%
          oszczędności), a okna w nawiewniki – celem uniknięcia efektu termosu,
          tj. wykwitów na ścianach.
        </Text>
      </div>
      <div className='warm-info-section'>
        <Text>
          Oszczędności daje wilgotne powietrze, które jest odczuwalnie
          cieplejsze niż powietrze suche. Warto utrzymywać w mieszkaniu większą
          wilgotność dzięki nawilżaczom powietrza i zielonym roślinom.
        </Text>
      </div>
      <div className='warm-info-section'>
        <Text>
          Energia cieplna dłużej zostanie w mieszkaniu, jeśli na czas naszej
          nieobecności nie wyłączymy wszystkich grzejników. Wychłodzenie
          mieszkania nie nastąpi, a zostanie utrzymana równomierna temperatura.
          Do ogrzania wychłodzonego mieszkania potrzeba dużej ilości ciepła,
          dlatego nagrzanie jest dużo droższe od utrzymania stałej temperatury.
          Jeśli planujesz dłuższą nieobecność, zamknij wszystkie okna i otwórz
          wszystkie drzwi. W zależności od kubatury mieszkania, pozostaw
          włączony jeden lub dwa średnie grzejniki, resztę wyłącz.
        </Text>
      </div>
      <div className='warm-info-section'>
        <Text marginBottom={10} isBold>
          Aby mieć ciepło:
        </Text>
        <ul>
          <Text
            as='li'
            className={classNames('warm-info-text', { highContrast })}
          >
            Nie zabudowuj grzejników i zaworów termostatycznych
          </Text>

          <Text
            as='li'
            className={classNames('warm-info-text', { highContrast })}
          >
            Wietrz po „zakręceniu” kaloryferów
          </Text>

          <Text
            as='li'
            className={classNames('warm-info-text', { highContrast })}
          >
            Wietrz krótko i intensywnie
          </Text>

          <Text
            as='li'
            className={classNames('warm-info-text', { highContrast })}
          >
            Nie wyziębiaj mieszkania, gdyż jego ponowne ogrzanie powoduje
            większe zużycie energii cieplnej
          </Text>

          <Text
            as='li'
            className={classNames('warm-info-text', { highContrast })}
          >
            Nie wychładzaj mieszkania podczas dłuższej nieobecności
          </Text>

          <Text
            as='li'
            className={classNames('warm-info-text', { highContrast })}
          >
            Zielone rośliny zwiększają komfort cieplny
          </Text>
        </ul>
      </div>
    </div>
  );
};

export default WarmInfo;
