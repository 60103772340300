import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useContrast } from '../../../context/ContrastContext';
import Icon from '../Icon/Icon';

import './BackButton.scss';

const BackButton: React.FC = () => {
  const navigate = useNavigate();
  const { highContrast } = useContrast();

  const onClick = () => {
    navigate(-1);
  };

  return (
    <button
      className={classNames('back-button', { highContrast })}
      onClick={onClick}
    >
      <Icon icon={faArrowLeft} size='lg' />
    </button>
  );
};

export default BackButton;
