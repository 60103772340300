import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { useContrast } from '../../context/ContrastContext';
import Icon from '../core/Icon/Icon';
import Text from '../core/Text/Text';

import './Footer.scss';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const { highContrast } = useContrast();

  return (
    <footer className={classNames('footer', { highContrast })}>
      <div className='footer-section'>
        <Text className='footer-text'>
          Spółdzielnia Mieszkaniowa „NOWA” w Olkuszu
        </Text>
        <Text className='footer-text'>ul. Gen. Stefana Buchowieckiego 37</Text>
        <Text className='footer-text'>32-300 Olkusz</Text>
        <div className='row'>
          <Icon icon={faPhone} />
          <Text className='footer-text'>32/ 643 37 98</Text>
        </div>
        <a href='mailto:sekretariat@sm-nowa.olkusz.pl'>
          <div className='row'>
            <Icon icon={faEnvelope} />
            <Text className='footer-text'>sekretariat@sm-nowa.olkusz.pl</Text>
          </div>
        </a>
      </div>

      <div className='crafted'>
        <div>
          <Text
            className='footer-text'
            fontSize={{
              min: '0.75rem',
              preferred: '0.2rem + 0.8vw',
              max: '1rem',
            }}
          >
            © {currentYear} Wszelkie prawa zastrzeżone
          </Text>
        </div>
        <Link to='https://happy-coding.pl/'>
          <Text
            className='footer-text'
            fontSize={{
              min: '0.75rem',
              preferred: '0.2rem + 0.8vw',
              max: '1rem',
            }}
          >
            by Happy Coding
          </Text>
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
