import React from 'react';
import './Pagination.scss';
import classNames from 'classnames';

import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContrast } from '../../../context/ContrastContext';

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
};

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const { highContrast } = useContrast();

  const handleClick = (page: number) => {
    if (page !== currentPage) {
      onPageChange(page);
    }
  };

  const getPaginationRange = () => {
    const range = [];
    const neighborCount = 1;

    // Always include the first page
    range.push(1);

    // Add ellipsis if current page is far from the first page
    if (currentPage - neighborCount > 2) {
      range.push('...');
    }

    // Add neighbors around the current page
    for (
      let i = Math.max(2, currentPage - neighborCount);
      i <= Math.min(totalPages - 1, currentPage + neighborCount);
      i++
    ) {
      range.push(i);
    }

    // Add ellipsis if current page is far from the last page
    if (currentPage + neighborCount < totalPages - 1) {
      range.push('...');
    }

    // Always include the last page
    if (totalPages > 1) {
      range.push(totalPages);
    }

    return range;
  };

  const paginationRange = getPaginationRange();

  return (
    <div className='pagination'>
      <button
        aria-label='Poprzednia strona'
        className={classNames('arrow-button', { highContrast })}
        disabled={currentPage === 1}
        onClick={() => handleClick(currentPage - 1)}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      {paginationRange.map((page, index) =>
        page === '...' ? (
          <span key={`ellipsis-${index}`} className='ellipsis'>
            ...
          </span>
        ) : (
          <button
            key={page}
            className={classNames('page-button', {
              active: currentPage === page,
              highContrast,
            })}
            onClick={() => handleClick(Number(page))}
          >
            {page}
          </button>
        )
      )}
      <button
        aria-label='Następna strona'
        className={classNames('arrow-button', { highContrast })}
        disabled={currentPage === totalPages}
        onClick={() => handleClick(currentPage + 1)}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};

export default Pagination;
