import Highlight from '../components/Highlight/Highlight';
import React from 'react';
import {
  faBuilding,
  faHammer,
  faCheck,
  faUser,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import Icon from '../components/core/Icon/Icon';

import budowa from '../assets/images/history/budowa.jpeg';
import budowa2 from '../assets/images/history/budowa2.jpeg';
import map from '../assets/images/history/map.jpeg';
import mieszkancy from '../assets/images/history/mieszkancy.jpeg';
import mieszkancy2 from '../assets/images/history/mieszkancy2.jpeg';

// budowa
import budowa_WEBP_2000px_2x from '../assets/images/history/budowa_2000px_2x.webp';
import budowa_WEBP_1400px_2x from '../assets/images/history/budowa_1400px_2x.webp';
import budowa_WEBP_800px_2x from '../assets/images/history/budowa_800px_2x.webp';

import budowa_WEBP_2000px_1x from '../assets/images/history/budowa_2000px_1x.webp';
import budowa_WEBP_1400px_1x from '../assets/images/history/budowa_1400px_1x.webp';
import budowa_WEBP_800px_1x from '../assets/images/history/budowa_800px_1x.webp';

import budowa_JPG_2000px_2x from '../assets/images/history/budowa_2000px_2x.jpg';
import budowa_JPG_1400px_2x from '../assets/images/history/budowa_1400px_2x.jpg';
import budowa_JPG_800px_2x from '../assets/images/history/budowa_800px_2x.jpg';

import budowa_JPG_2000px_1x from '../assets/images/history/budowa_2000px_1x.jpg';
import budowa_JPG_1400px_1x from '../assets/images/history/budowa_1400px_1x.jpg';
import budowa_JPG_800px_1x from '../assets/images/history/budowa_800px_1x.jpg';

// budowa2
import budowa2_WEBP_2000px_2x from '../assets/images/history/budowa2_2000px_2x.webp';
import budowa2_WEBP_1400px_2x from '../assets/images/history/budowa2_1400px_2x.webp';
import budowa2_WEBP_800px_2x from '../assets/images/history/budowa2_800px_2x.webp';

import budowa2_WEBP_2000px_1x from '../assets/images/history/budowa2_2000px_1x.webp';
import budowa2_WEBP_1400px_1x from '../assets/images/history/budowa2_1400px_1x.webp';
import budowa2_WEBP_800px_1x from '../assets/images/history/budowa2_800px_1x.webp';

import budowa2_JPG_2000px_2x from '../assets/images/history/budowa2_2000px_2x.jpg';
import budowa2_JPG_1400px_2x from '../assets/images/history/budowa2_1400px_2x.jpg';
import budowa2_JPG_800px_2x from '../assets/images/history/budowa2_800px_2x.jpg';

import budowa2_JPG_2000px_1x from '../assets/images/history/budowa2_2000px_1x.jpg';
import budowa2_JPG_1400px_1x from '../assets/images/history/budowa2_1400px_1x.jpg';
import budowa2_JPG_800px_1x from '../assets/images/history/budowa2_800px_1x.jpg';

// map
import map_WEBP_2000px_2x from '../assets/images/history/map_2000px_2x.webp';
import map_WEBP_1400px_2x from '../assets/images/history/map_1400px_2x.webp';
import map_WEBP_800px_2x from '../assets/images/history/map_800px_2x.webp';

import map_WEBP_2000px_1x from '../assets/images/history/map_2000px_1x.webp';
import map_WEBP_1400px_1x from '../assets/images/history/map_1400px_1x.webp';
import map_WEBP_800px_1x from '../assets/images/history/map_800px_1x.webp';

import map_JPG_2000px_2x from '../assets/images/history/map_2000px_2x.jpg';
import map_JPG_1400px_2x from '../assets/images/history/map_1400px_2x.jpg';
import map_JPG_800px_2x from '../assets/images/history/map_800px_2x.jpg';

import map_JPG_2000px_1x from '../assets/images/history/map_2000px_1x.jpg';
import map_JPG_1400px_1x from '../assets/images/history/map_1400px_1x.jpg';
import map_JPG_800px_1x from '../assets/images/history/map_800px_1x.jpg';

// mieszkancy
import mieszkancy_WEBP_2000px_2x from '../assets/images/history/mieszkancy_2000px_2x.webp';
import mieszkancy_WEBP_1400px_2x from '../assets/images/history/mieszkancy_1400px_2x.webp';
import mieszkancy_WEBP_800px_2x from '../assets/images/history/mieszkancy_800px_2x.webp';

import mieszkancy_WEBP_2000px_1x from '../assets/images/history/mieszkancy_2000px_1x.webp';
import mieszkancy_WEBP_1400px_1x from '../assets/images/history/mieszkancy_1400px_1x.webp';
import mieszkancy_WEBP_800px_1x from '../assets/images/history/mieszkancy_800px_1x.webp';

import mieszkancy_JPG_2000px_2x from '../assets/images/history/mieszkancy_2000px_2x.jpg';
import mieszkancy_JPG_1400px_2x from '../assets/images/history/mieszkancy_1400px_2x.jpg';
import mieszkancy_JPG_800px_2x from '../assets/images/history/mieszkancy_800px_2x.jpg';

import mieszkancy_JPG_2000px_1x from '../assets/images/history/mieszkancy_2000px_1x.jpg';
import mieszkancy_JPG_1400px_1x from '../assets/images/history/mieszkancy_1400px_1x.jpg';
import mieszkancy_JPG_800px_1x from '../assets/images/history/mieszkancy_800px_1x.jpg';

// mieszkancy2 images
import mieszkancy2_WEBP_2000px_2x from '../assets/images/history/mieszkancy2_2000px_2x.webp';
import mieszkancy2_WEBP_1400px_2x from '../assets/images/history/mieszkancy2_1400px_2x.webp';
import mieszkancy2_WEBP_800px_2x from '../assets/images/history/mieszkancy2_800px_2x.webp';

import mieszkancy2_WEBP_2000px_1x from '../assets/images/history/mieszkancy2_2000px_1x.webp';
import mieszkancy2_WEBP_1400px_1x from '../assets/images/history/mieszkancy2_1400px_1x.webp';
import mieszkancy2_WEBP_800px_1x from '../assets/images/history/mieszkancy2_800px_1x.webp';

import mieszkancy2_JPG_2000px_2x from '../assets/images/history/mieszkancy2_2000px_2x.jpg';
import mieszkancy2_JPG_1400px_2x from '../assets/images/history/mieszkancy2_1400px_2x.jpg';
import mieszkancy2_JPG_800px_2x from '../assets/images/history/mieszkancy2_800px_2x.jpg';

import mieszkancy2_JPG_2000px_1x from '../assets/images/history/mieszkancy2_2000px_1x.jpg';
import mieszkancy2_JPG_1400px_1x from '../assets/images/history/mieszkancy2_1400px_1x.jpg';
import mieszkancy2_JPG_800px_1x from '../assets/images/history/mieszkancy2_800px_1x.jpg';

export interface HistoryItem {
  icon: React.ReactNode;
  title: string;
  date: string;
  description: string;
  image: ImageType;
  bottom?: React.ReactNode;
}

export type ImageType =
  | 'budowa'
  | 'budowa2'
  | 'mieszkancy'
  | 'mieszkancy2'
  | 'map';

type ImageSource = {
  [key in ImageType]: {
    webp: {
      '800px': {
        '1x': string;
        '2x': string;
      };
      '1400px': {
        '1x': string;
        '2x': string;
      };
      '2000px': {
        '1x': string;
        '2x': string;
      };
    };
    jpg: {
      '800px': {
        '1x': string;
        '2x': string;
      };
      '1400px': {
        '1x': string;
        '2x': string;
      };
      '2000px': {
        '1x': string;
        '2x': string;
      };
    };
  };
};

export const imageSource: ImageSource = {
  budowa: {
    webp: {
      '800px': { '1x': budowa_WEBP_800px_1x, '2x': budowa_WEBP_800px_2x },
      '1400px': { '1x': budowa_WEBP_1400px_1x, '2x': budowa_WEBP_1400px_2x },
      '2000px': { '1x': budowa_WEBP_2000px_1x, '2x': budowa_WEBP_2000px_2x },
    },
    jpg: {
      '800px': { '1x': budowa_JPG_800px_1x, '2x': budowa_JPG_800px_2x },
      '1400px': { '1x': budowa_JPG_1400px_1x, '2x': budowa_JPG_1400px_2x },
      '2000px': { '1x': budowa_JPG_2000px_1x, '2x': budowa_JPG_2000px_2x },
    },
  },
  budowa2: {
    webp: {
      '800px': { '1x': budowa2_WEBP_800px_1x, '2x': budowa2_WEBP_800px_2x },
      '1400px': { '1x': budowa2_WEBP_1400px_1x, '2x': budowa2_WEBP_1400px_2x },
      '2000px': { '1x': budowa2_WEBP_2000px_1x, '2x': budowa2_WEBP_2000px_2x },
    },
    jpg: {
      '800px': { '1x': budowa2_JPG_800px_1x, '2x': budowa2_JPG_800px_2x },
      '1400px': { '1x': budowa2_JPG_1400px_1x, '2x': budowa2_JPG_1400px_2x },
      '2000px': { '1x': budowa2_JPG_2000px_1x, '2x': budowa2_JPG_2000px_2x },
    },
  },
  mieszkancy: {
    webp: {
      '800px': {
        '1x': mieszkancy_WEBP_800px_1x,
        '2x': mieszkancy_WEBP_800px_2x,
      },
      '1400px': {
        '1x': mieszkancy_WEBP_1400px_1x,
        '2x': mieszkancy_WEBP_1400px_2x,
      },
      '2000px': {
        '1x': mieszkancy_WEBP_2000px_1x,
        '2x': mieszkancy_WEBP_2000px_2x,
      },
    },
    jpg: {
      '800px': { '1x': mieszkancy_JPG_800px_1x, '2x': mieszkancy_JPG_800px_2x },
      '1400px': {
        '1x': mieszkancy_JPG_1400px_1x,
        '2x': mieszkancy_JPG_1400px_2x,
      },
      '2000px': {
        '1x': mieszkancy_JPG_2000px_1x,
        '2x': mieszkancy_JPG_2000px_2x,
      },
    },
  },
  mieszkancy2: {
    webp: {
      '800px': {
        '1x': mieszkancy2_WEBP_800px_1x,
        '2x': mieszkancy2_WEBP_800px_2x,
      },
      '1400px': {
        '1x': mieszkancy2_WEBP_1400px_1x,
        '2x': mieszkancy2_WEBP_1400px_2x,
      },
      '2000px': {
        '1x': mieszkancy2_WEBP_2000px_1x,
        '2x': mieszkancy2_WEBP_2000px_2x,
      },
    },
    jpg: {
      '800px': {
        '1x': mieszkancy2_JPG_800px_1x,
        '2x': mieszkancy2_JPG_800px_2x,
      },
      '1400px': {
        '1x': mieszkancy2_JPG_1400px_1x,
        '2x': mieszkancy2_JPG_1400px_2x,
      },
      '2000px': {
        '1x': mieszkancy2_JPG_2000px_1x,
        '2x': mieszkancy2_JPG_2000px_2x,
      },
    },
  },
  map: {
    webp: {
      '800px': { '1x': map_WEBP_800px_1x, '2x': map_WEBP_800px_2x },
      '1400px': { '1x': map_WEBP_1400px_1x, '2x': map_WEBP_1400px_2x },
      '2000px': { '1x': map_WEBP_2000px_1x, '2x': map_WEBP_2000px_2x },
    },
    jpg: {
      '800px': { '1x': map_JPG_800px_1x, '2x': map_JPG_800px_2x },
      '1400px': { '1x': map_JPG_1400px_1x, '2x': map_JPG_1400px_2x },
      '2000px': { '1x': map_JPG_2000px_1x, '2x': map_JPG_2000px_2x },
    },
  },
};

export const historyItems: HistoryItem[] = [
  {
    icon: <Icon icon={faBuilding} />,
    title: 'Powstanie Spółdzielni',
    date: '26.06.1990',
    description:
      'Zgodnie z Uchwałą Nr 8 Zebrania Przedstawicieli Członków Spółdzielni "METALURG" Spółdzielnia Mieszkaniowa „NOWA” w Olkuszu powstała w wyniku podziału Spółdzielni Mieszkaniowej "METALURG" w Dąbrowie Górniczej. Uchwała o podziale Spółdzielni Mieszkaniowej "METALURG" w Dąbrowie Górniczej stanowiła wyodrębnienie zasobów mieszkaniowych zlokalizowanych w Olkuszu na Osiedlu "Słowiki" oraz przenosiła prawa i zobowiązania dotyczące osiedla na nowo powstającą Spółdzielnię Mieszkaniową „NOWA” w Olkuszu.',
    image: 'budowa',
    bottom: (
      <Highlight text='Osiedla "Słowiki" składające się z 22 budynków spółdzielczych z 944 mieszkaniami zostało wybudowane dla potrzeb Koksowni "Przyjaźń", stanowiącej część Kombinatu Metalurgicznego Huty Katowice. W 1995 roku Spółdzielnia przejęła 9 budynków funkcyjnych z 322 mieszkaniami od Zakładów Koksowniczych "Przyjaźń" w Dąbrowie Górniczej.' />
    ),
  },
  {
    icon: <Icon icon={faHammer} />,
    title: 'Budowa Osiedla',
    date: '1984-1989',
    description:
      'Budowa osiedla mieszkaniowego była realizowana etapami w latach 1984-1989. Równocześnie realizowana była infrastruktura towarzysząca tj. budowa szkoły podstawowej, przedszkola oraz żłobka.',
    image: 'budowa2',
  },
  {
    icon: <Icon icon={faCheck} />,
    title: 'Rejestracja Spółdzielni',
    date: '24.07.1990',
    description:
      'Spółdzielnia Mieszkaniowa „NOWA” w Olkuszu została zarejestrowana w Sądzie Rejestrowym w Katowicach.',
    image: 'map',
  },
  {
    icon: <Icon icon={faUser} />,
    title: 'Pierwsi mieszkańcy',
    date: '1986',
    description:
      'Pierwsi mieszkańcy otrzymali klucze do mieszkań w budynku przy ul. Kosynierów 2 już w 1986 roku.',
    image: 'mieszkancy',
  },
  {
    icon: <Icon icon={faHome} />,
    title: 'Aktualny stan',
    date: 'Obecnie',
    description:
      'Spółdzielnia Mieszkaniowa „NOWA” w Olkuszu posiada 31 budynków z 1301 mieszkaniami.',
    image: 'mieszkancy2',
    bottom: (
      <Highlight text='Zlokalizowanie Osiedla "Słowiki" w Olkuszu oprócz ciekawej architektonicznie zabudowy wyróżniającej się na tle istniejących budynków mieszkaniowych spowodowało zainwestowanie Huty Katowice w rozbudowę infrastruktury technicznej i społecznej w Olkuszu. Wraz z budową osiedla dokończono budowę Hali Widowiskowo-Sportowej, wybudowano przedszkole przy ul. Polnej, szkołę podstawową przy ul. Korczaka oraz rozbudowano stację uzdatniania wody i oczyszczalnię ścieków.' />
    ),
  },
];
