import { faDownload } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import Icon from '../core/Icon/Icon';
import Text from '../core/Text/Text';
import { useContrast } from '../../context/ContrastContext';

import './DocumentItem.scss';

interface DocumentItemProps {
  name: string | React.ReactNode;
  downloadLink?: string;
  onClick?: (event: React.MouseEvent) => void;
}

const DocumentItem: React.FC<DocumentItemProps> = ({
  name,
  downloadLink,
  onClick,
}) => {
  const { highContrast } = useContrast();

  return (
    <div className='document-item' onClick={(event) => onClick?.(event)}>
      {downloadLink && (
        <a className='link-box' href={downloadLink} download rel='noreferrer'>
          <Icon
            color={highContrast ? 'var(--spol-white)' : 'var(--spol-primary)'}
            icon={faDownload}
          />
          <Text className='name'>{name}</Text>
        </a>
      )}
    </div>
  );
};

export default DocumentItem;
