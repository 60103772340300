import axios from 'axios';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useContrast } from '../../context/ContrastContext';
import Error from '../core/Error/Error';
import HtmlText from '../core/HtmlText/HtmlText';
import Icon from '../core/Icon/Icon';
import Loader from '../core/Loader/Loader';
import Text from '../core/Text/Text';
import DocumentItem from '../DocumentItem/DocumentItem';
import Subheader from '../Subheader/Subheader';

import './SinglePost.scss';
import Lightbox from '../core/Lightbox/Lightbox';

export type Post = {
  id: number;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
  date: string;
  gallery_photos?: {
    value: Array<{
      guid: string;
    }>;
  };
  gallery_link?: {
    value: string;
  };
  documents?: {
    value: Array<{
      ID: string;
      guid: string;
      post_title: string;
    }>;
  };
};

const SinglePost: React.FC = () => {
  const { highContrast } = useContrast();

  const { id } = useParams<{ id: string }>();
  const [post, setPost] = useState<Post | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(
          `https://happy-coding.pl/wp-json/wp/v2/posts/${id}`
        );
        setPost(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching post');
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  const openLightbox = (index: number) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  if (loading)
    return (
      <div className='loader-wrapper'>
        <Loader />
      </div>
    );

  if (error)
    return (
      <div className='single-post-container'>
        <Error message='Przepraszamy, nie udało się załadować treści. Spróbuj ponownie później.' />
      </div>
    );

  return (
    <div className='single-post-container'>
      {post && (
        <>
          <Subheader title={post.title.rendered} inPost />
          <HtmlText
            dangerouslySetInnerHTML={{
              __html: post.content.rendered,
            }}
          />
          {/* Galeria */}
          {post.gallery_photos?.value && post.gallery_photos.value.length > 0 && (
            <div className='post-images'>
              {post.gallery_photos.value.map((image, index) => (
                <figure
                  className='post-image-container'
                  key={index}
                  onClick={() => openLightbox(index)}
                >
                  <img
                    src={image.guid}
                    alt={`Galeria ${index + 1}`}
                    className='post-image'
                  />
                </figure>
              ))}
            </div>
          )}
          {post.gallery_link?.value && (
            <a rel='noreferrer' href={post.gallery_link?.value}>
              <div className='more-gallery-link'>
                <Text isBold>Obejrzyj więcej zdjęć</Text>
                <Icon
                  className={classNames('icon', { highContrast })}
                  icon={faChevronRight}
                />
              </div>
            </a>
          )}
          <Text marginTop={24} marginBottom={24} className='post-date'>
            Opublikowano: {new Date(post.date).toLocaleDateString()}
          </Text>

          {/* Załączniki */}
          {post.documents?.value && post.documents.value.length > 0 && (
            <div className='post-documents'>
              <Subheader title='Załączniki' />
              <ul>
                {post.documents.value.map((doc) => (
                  <DocumentItem
                    key={doc.ID}
                    name={doc.post_title}
                    downloadLink={doc.guid}
                  />
                ))}
              </ul>
            </div>
          )}
        </>
      )}

      {isOpen && post?.gallery_photos?.value && (
        <Lightbox
          isOpen={isOpen}
          photoIndex={photoIndex}
          post={post}
          setIsOpen={setIsOpen}
          setPhotoIndex={setPhotoIndex}
        />
      )}
    </div>
  );
};

export default SinglePost;
