import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import HtmlText from '../core/HtmlText/HtmlText';
import Text from '../core/Text/Text';

import { cleanHtml } from '../../utils/cleanHtml';

import './Subheader.scss';
import { useContrast } from '../../context/ContrastContext';

interface HeaderProps {
  title: string | React.ReactNode;
  inPost?: boolean;
}

const Subheader: React.FC<HeaderProps> = ({ title, inPost = false }) => {
  const { highContrast } = useContrast();
  const isHtmlContent = typeof title !== 'string';
  const subheaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const subheaderElement = subheaderRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          subheaderElement?.classList.add('animate');
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (subheaderElement) {
      observer.observe(subheaderElement);
    }

    return () => {
      if (subheaderElement) {
        observer.unobserve(subheaderElement);
      }
    };
  }, []);

  return (
    <div
      className={classNames('subheader-container', {
        'in-post': inPost,
      })}
      ref={subheaderRef}
    >
      {isHtmlContent ? (
        <HtmlText
          fontSize={{
            min: '1rem',
            preferred: '0.8rem + 1vw',
            max: '1.25rem',
          }}
          as='h2'
          className={classNames('subheader', { highContrast })}
          dangerouslySetInnerHTML={{ __html: cleanHtml(title as string) }}
        />
      ) : (
        <Text
          fontSize={{
            min: '1rem',
            preferred: '0.8rem + 1vw',
            max: '1.25rem',
          }}
          as='h2'
          className={classNames('subheader', { highContrast })}
        >
          {title}
        </Text>
      )}
    </div>
  );
};

export default Subheader;
