import React from 'react';
import './HomeSecurityInfo.scss';
import Text from '../core/Text/Text';
import classNames from 'classnames';

import { useContrast } from '../../context/ContrastContext';

const HomeSecurityInfo: React.FC = () => {
  const { highContrast } = useContrast();

  return (
    <div className='security-info-container'>
      <div className='security-info-section'>
        <Text>
          Włamania do mieszkań stają się coraz bardziej powszechne, dlatego
          warto znać skuteczne sposoby zabezpieczenia swojego lokalu.
          Przestrzeganie kilku prostych zasad może znacznie zmniejszyć ryzyko
          włamania i zapewnić bezpieczeństwo.
        </Text>
      </div>
      <div className='security-info-section'>
        <Text isBold>Drzwi i Okna</Text>
        <Text>
          Upewnij się, że wszystkie drzwi i okna w Twoim mieszkaniu są solidne i
          wyposażone w odpowiednie zamki. Warto zainstalować dodatkowe
          zabezpieczenia, takie jak zamki wielopunktowe czy antywłamaniowe
          rolety. Szczególną uwagę zwróć na drzwi wejściowe oraz balkony, które
          mogą być łatwym celem dla włamywaczy.
        </Text>
      </div>
      <div className='security-info-section'>
        <Text isBold>Oświetlenie</Text>
        <Text>
          Zadbaj o odpowiednie oświetlenie w okolicy drzwi i okien. Warto
          zainstalować oświetlenie z czujnikami ruchu, które może skutecznie
          odstraszyć potencjalnych włamywaczy. Dodatkowo, symulowanie obecności
          w mieszkaniu poprzez automatyczne włączanie i wyłączanie świateł może
          zniechęcić złodziei.
        </Text>
      </div>
      <div className='security-info-section'>
        <Text isBold>Sąsiedzkie Czuwanie</Text>
        <Text>
          Współpracuj z sąsiadami i angażuj się w inicjatywy sąsiedzkiego
          czuwania. Informuj sąsiadów o każdej podejrzanej aktywności w okolicy
          i zachęcaj do wzajemnego dbania o bezpieczeństwo swoich mieszkań.
        </Text>
      </div>
      <div className='security-info-section'>
        <Text marginBottom={10} isBold>
          Wskazówki dotyczące bezpieczeństwa:
        </Text>
        <ul>
          <Text
            as='li'
            className={classNames('security-reminder-text', { highContrast })}
          >
            Nigdy nie zostawiaj kluczy pod wycieraczką ani w innych łatwo
            dostępnych miejscach
          </Text>
          <Text
            as='li'
            className={classNames('security-reminder-text', { highContrast })}
          >
            Unikaj informowania nieznajomych o swoich planach wyjazdowych
          </Text>
          <Text
            as='li'
            className={classNames('security-reminder-text', { highContrast })}
          >
            Regularnie sprawdzaj stan zamków, drzwi i okien w swoim mieszkaniu
          </Text>
          <Text
            as='li'
            className={classNames('security-reminder-text', { highContrast })}
          >
            Unikaj przechowywania wartościowych przedmiotów w miejscach
            widocznych z zewnątrz
          </Text>
        </ul>
      </div>
    </div>
  );
};

export default HomeSecurityInfo;
