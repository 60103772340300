import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import './HeroImages.scss';
import Text from '../core/Text/Text';
import { scrollToElementWithOffset } from '../../utils/scrollToElementWithOffset';
import useMediaQuery from '../../hooks/useMediaQuery';

import { imageSources, type ImageType } from './imageSources';

interface ImageSet {
  [key: number]: {
    image: ImageType;
    isFading: boolean;
  };
}

const HeroImages: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [imageSet, setImageSet] = useState<ImageSet>({
    0: { image: 'hero1', isFading: false },
    1: { image: 'hero3', isFading: false },
    2: { image: 'hero2', isFading: false },
  });

  const imageRefs = useRef<(HTMLDivElement | null)[]>([]);

  const getRandomImage = (currentImages: ImageType[]): ImageType => {
    const images: ImageType[] = ['hero1', 'hero2', 'hero3', 'hero4', 'hero5', 'hero6', 'hero7', 'hero8'];
    let randomImage: ImageType;
    do {
      const randomIndex = Math.floor(Math.random() * images.length);
      randomImage = images[randomIndex];
    } while (currentImages.includes(randomImage));

    return randomImage;
  };


  const updateOneImageSet = (index: number) => {
    // Set the fading state for the image being updated (first stage)
    setImageSet((prevState) => {
      const updatedImageSet = { ...prevState };
      updatedImageSet[index] = { ...updatedImageSet[index], isFading: true };
      return updatedImageSet;
    });

    // Wait a short duration to allow fading animation to run before updating the image (second stage)
    setTimeout(() => {
      setImageSet((prevState) => {
        const updatedImageSet = { ...prevState };

        const currentImages = Object.values(updatedImageSet).map((item) => item.image);
        const randomImage = getRandomImage(currentImages);

        updatedImageSet[index] = { image: randomImage, isFading: false };
        return updatedImageSet;
      });
    }, 1000);
  };



  useEffect(() => {
    let currentIndex = 0; // Track which image to update next

    const sequentialUpdate = () => {
      updateOneImageSet(currentIndex); // Update the current image
      currentIndex = (currentIndex + 1) % 3; // Move to the next image (cycle through 0, 1, 2)
    };

    // Trigger the first update and start the interval
    const interval = setInterval(() => {
      sequentialUpdate();
    }, 5000); // 5 seconds between updates

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChevronClick = () => {
    scrollToElementWithOffset({ selector: 'news' });
  };

  // Helper function to render picture element
  const heroImage = (index: number) => {
    const { image, isFading } = imageSet[index];
    const imageSource = imageSources[image];
    return (
      <figure className={classNames('front', { fading: isFading })}>
        <picture>
          <source
            srcSet={`${imageSource.webp['800px']['1x']} 1x,
            ${imageSource.webp['1400px']['1x']} 1x,
            ${imageSource.webp['2000px']['1x']} 1x,
            ${imageSource.webp['800px']['2x']} 2x,
            ${imageSource.webp['1400px']['2x']} 2x,
            ${imageSource.webp['2000px']['2x']} 2x`}
            type='image/webp'
            sizes='(max-width: 800px) 800px, (max-width: 1400px) 1400px, 2000px'
          />
          <source
            srcSet={`${imageSource.jpg['800px']['1x']} 1x,
            ${imageSource.jpg['1400px']['1x']} 1x,
            ${imageSource.jpg['2000px']['1x']} 1x,
            ${imageSource.jpg['800px']['2x']} 2x,
            ${imageSource.jpg['1400px']['2x']} 2x,
            ${imageSource.jpg['2000px']['2x']} 2x`}
            type='image/jpeg'
            sizes='(max-width: 800px) 800px, (max-width: 1400px) 1400px, 2000px'
          />
          <img
            src={imageSource.jpg['800px']['1x']}
            alt={`Hero ${index + 1}`}
            className='front-img'
          />
        </picture>
      </figure>
    );
  };

  return (
    <div className='hero-main'>
      {!isMobile && (
        <div className='hero-left' ref={(el) => (imageRefs.current[0] = el)}>
          <div
            className='hero-left-top'
            // className={classNames('hero-left-top', { flipped: flipped[0] })}
            // onClick={() => handleImageClick(0)}
          >
            {heroImage(0)}
            <div className='back' />
          </div>
        </div>
      )}
      <div className='hero-right'>
        <div className='hero-right-content'>
          <div
            className='hero-right-top'
            // className={classNames('hero-right-top', { flipped: flipped[1] })}
            // onClick={() => handleImageClick(1)}
            ref={(el) => (imageRefs.current[1] = el)}
          >
            {heroImage(1)}
            <div className='back' />
          </div>
          <div className='hero-right-center'>
            <div className='hero-message-texts'>
              <Text
                fontSize={{
                  min: '1.2rem',
                  preferred: '1rem + 1vw',
                  max: '1.8rem',
                }}
                isBold
                as='h1'
                className='hero-text main-text'
              >
                Spółdzielnia Mieszkaniowa „NOWA” w Olkuszu
              </Text>
              <div className='cta' onClick={onChevronClick}>
                {!isMobile && (
                  <Text isBold as='h2' className='hero-text secondary-text'>
                    Odkryj wyjątkową atmosferę naszego osiedla w kolorze
                    niebieskim
                  </Text>
                )}
                {/* <Icon
                  className={classNames('chevronDown', {
                    highContrast: highContrast,
                  })}
                  icon={faCaretDown}
                  size='2x'
                /> */}
              </div>
            </div>
          </div>
          <div
            className="hero-right-bottom"
            // className={classNames('hero-right-bottom', { flipped: flipped[2] })}
            // onClick={() => handleImageClick(2)}
            ref={(el) => (imageRefs.current[2] = el)}
          >
            {heroImage(2)}
            <div className='back' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroImages;
