import classNames from 'classnames';
import React, { FC } from 'react';

import { useContrast } from '../../../context/ContrastContext';

import './Text.scss';

interface FontSizeClamp {
  min: string;
  preferred: string;
  max: string;
}

interface TextProps {
  as?: keyof JSX.IntrinsicElements;
  fontSize?: string | number | FontSizeClamp;
  isBold?: boolean;
  marginTop?: string | number;
  marginBottom?: string | number;
  className?: string;
  children: React.ReactNode;
}

const Text: FC<TextProps> = ({
  as: Tag = 'p',
  fontSize = {
    min: '0.875rem',
    preferred: '0.75rem + 0.8vw',
    max: '1rem',
  },
  isBold = false,
  marginTop = 0,
  marginBottom = 0,
  className = '',
  children,
}) => {
  const { highContrast } = useContrast();

  // Convert fontSize prop to a valid CSS value
  let fontSizeValue: string;
  if (typeof fontSize === 'object') {
    fontSizeValue = `clamp(${fontSize.min}, ${fontSize.preferred}, ${fontSize.max})`;
  } else {
    fontSizeValue = typeof fontSize === 'number' ? `${fontSize}rem` : fontSize;
  }

  const computedLineHeight = `calc(${fontSizeValue} * 1.75)`;

  const style = {
    fontSize: fontSizeValue,
    lineHeight: computedLineHeight,
    fontWeight: isBold ? 'bold' : 'normal',
    marginTop,
    marginBottom,
  };

  return (
    <Tag
      className={classNames('text', { [className]: !!className, highContrast })}
      style={style}
    >
      {children}
    </Tag>
  );
};

export default Text;
